import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array,
    order: Object
  }

  connect() {
    console.log("Map controller connected");
    this.controllerConnected = true;

    mapboxgl.accessToken = this.apiKeyValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v12"
    })

    this.markerObjects = [];
    this.#addHomeToMap()
    this.#addMarkersToMap()
    this.#fitMapToMarkers()

    // this.map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl})) // add search bar on map
  }

  #addMarkersToMap() {
    this.markersValue.forEach((marker) => {
      const popup = new mapboxgl.Popup().setHTML(marker.info_window_html)

      const customMarker = document.createElement("div");
      customMarker.innerHTML = marker.marker_html

      const mapboxMarker = new mapboxgl.Marker(customMarker)
        .setLngLat([ marker.lng, marker.lat ])
        .setPopup(popup)
        .addTo(this.map)
      this.markerObjects.push(mapboxMarker);

      const isMobileView = window.matchMedia('(max-width: 480px)').matches;
      console.log('isMobileView: ', isMobileView);

      // Add a click event listener to the marker
      customMarker.addEventListener('click', () => {
        // Get the size of the info window
        // const popup = document.querySelector('.mapboxgl-popup');

        const popupWidth = 240; //240
        const popupHeight = 316; //316

        // Calculate the center coordinates to fit the info window on a mobile view
        const mobileCenter = [
          marker.lng,
          marker.lat-0.0111, // - popupHeight / 2
        ];

        if (isMobileView) {
          // Center the map on the calculated mobileCenter
          this.map.flyTo({
            center: mobileCenter,
            zoom: 13.2, // You can set a specific zoom level here
            speed: 0.5, // How fast the animation will happen
            essential: true, // This ensures a smooth fly animation
          });
        }
      });
    })
  }

  #addHomeToMap() {
    const order = this.orderValue
    var marker = new mapboxgl.Marker({ color: '#f7839e' })
               .setLngLat([ order.longitude, order.latitude ]);
    // marker.getElement().innerHTML = '<i class="fa fa-home fa-3x" style="color: #f7839e;"></i>';
    marker.addTo(this.map);
  }

  #fitMapToMarkers() {
    const order = this.orderValue
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([ order.longitude, order.latitude ]);
    if (this.markersValue.length > 0) {
      this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
    }
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 4000 })
  }

  #clearMarkers() {
    this.markerObjects.forEach((marker) => {
      // Remove each marker from the map
      marker.remove();
    });

    // Clear the markers array
    this.markerObjects = [];
  }

  markersValueChanged() {
    if (!this.controllerConnected) {
      // This method somehow gets triggered even before the controller comes online so bypassing by force
      return;
    }
    this.#clearMarkers();
    this.#addMarkersToMap()
    this.#fitMapToMarkers()
  }
}
