import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"

// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static values = { apiKey: String }

  static targets = ["address"]

  connect() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "postcode,locality,neighborhood,address",
      countries: 'us',
      bbox: [-122.521177, 37.422098, -122.206687, 37.888391], //bbox around the Marina,
      placeholder: 'Type in your address',
    })
    this.geocoder.addTo(this.element)
    this.geocoder.on("result", event => this.#setInputValue(event))
    // Listen for the 'result' event, which contains the lat/long coordinates
    this.geocoder.on('result', (event) => {
      const result = event.result;

      const coordinates = result.geometry.coordinates;
      const latitude = coordinates[1];
      const longitude = coordinates[0];

      const street = result.text;
      const houseNumber = result.address || '';

      const cityContext = result.context.find((c) => c.id.startsWith('place.'));
      const stateContext = result.context.find((c) => c.id.startsWith('region.'));
      const zipCodeContext = result.context.find((c) => c.id.startsWith('postcode.'));
      const countryContext = result.context.find((c) => c.id.startsWith('country.'));

      const city = cityContext ? cityContext.text : '';
      const state = stateContext ? stateContext.short_code.split("-")[1] : 'CA';
      const zipCode = zipCodeContext ? zipCodeContext.text : '';
      const country = countryContext ? countryContext.text : '';

      // Set the latitude and longitude values in hidden fields
      document.getElementById('location_latitude').value = latitude;
      document.getElementById('location_longitude').value = longitude;
      document.getElementById('location_house_number').value = houseNumber;
      document.getElementById('location_street').value = street;
      document.getElementById('location_city').value = city;
      document.getElementById('location_state').value = state;
      document.getElementById('location_zip_code').value = zipCode;
      document.getElementById('location_country').value = country;

      var submitButton = document.getElementById('order-form').querySelector('input[type="submit"]');
      submitButton.click();
    });
    this.geocoder.on("clear", () => this.#clearInputValue())
  }

  #setInputValue(event) {
    this.addressTarget.value = event.result["place_name"]
  }

  #clearInputValue() {
    this.addressTarget.value = ""
  }

  disconnect() {
    this.geocoder.onRemove()
  }
}
